import React from "react";

const ContactUs = () => {
  return (
    <div>
      <div
        className="contact-bg container-fluid text-light mt-5 wow fadeInUp"
        data-wow-delay="0.1s"
      >
        <div className="contact-bg container">
          <div className="row gx-3">
            <div className="col-lg-4 col-md-12 pt-5 mb-5">
              <a href="/" className="logo d-flex align-items-center mb-4">
                <img src="assets/img/logo.png" />
              </a>
              <div className="d-flex mb-3">
                <i className="bi bi-geo-alt text-primary me-2"></i>
                <p className="mb-0">SVIET college, Banur, India</p>
              </div>
              <div className="d-flex mb-3">
                <i className="bi bi-envelope-open text-primary me-2"></i>
                <p className="mb-0">acctcomputing24@gmail.com</p>
              </div>
              <div className="d-flex mb-3">
                <i className="bi bi-telephone text-primary me-2"></i>
                <p className="mb-0">+91 9718322373</p>
              </div>
              {/* <div className="d-flex mt-4">
                <a className="btn btn-primary btn-square me-2" href="#">
                      <i className="fab fa-twitter fw-normal"></i>
                    </a>
                <a
                  className="btn btn-primary btn-square me-2"
                  href="https://www.facebook.com/profile.php?id=100094998456201"
                >
                  <i className="fab fa-facebook-f fw-normal"></i>
                </a>
                <a
                  className="btn btn-primary btn-square me-2"
                  href="https://www.linkedin.com/in/iamrf-awards-992b47283/"
                >
                  <i className="fab fa-linkedin-in fw-normal"></i>
                </a>
                <a className="btn btn-primary btn-square" href="#">
                      <i className="fab fa-instagram fw-normal"></i>
                    </a>
              </div> */}
            </div>
            <div className="contact-map col-lg-8 col-md-12 pt-0 pt-lg-5 mb-5">
              <iframe
                className="position-relative rounded w-100 h-100"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3436.6576639378286!2d76.67317797546586!3d30.53071787468244!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390fc2e25466d5e7%3A0xf0bbb65fa7fca376!2sSwami%20Vivekanand%20Institute%20of%20Engineering%20%26%20Technology!5e0!3m2!1sen!2sin!4v1733415566307!5m2!1sen!2sin"
                style={{ minHeight: "250px", border: "0" }}
                allowFullScreen=""
                aria-hidden="false"
                tabIndex="0"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
